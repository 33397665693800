<template>
    <div>
        <div class="top">
            <div class="tit">按{{ date }}</div>
            <!-- <div class="screen" @click="changeScreen">筛选</div> -->
            <div class="date" @click="changeDate">时间范围<img src="../../../assets/home/xtb_sx.png" alt=""></div>
        </div>
        <div class="center">
            <div class="item" v-for="(item, i) in list" :key="i">
                <div class="frame" v-if="date == '日'">{{ item.TitleStart }}</div>
                <div class="frame" v-if="date == '周'">
                    {{ $moment(item.TitleStart).format('YYYY年') }}
                    <span>({{ $moment(item.TitleStart).format('MM/DD') }}~{{
                $moment(item.TitleEnd).format('MM/DD') }})</span>
                </div>
                <div class="frame" v-if="date == '半月'">
                    {{ $moment(item.TitleEnd).format('YYYY年MM月') }}
                    <span> {{ $moment(item.TitleEnd).format('DD') > 15 ? '下' : '上' }}</span>
                </div>
                <div class="frame" v-if="date == '月'"> {{ $moment(item.TitleEnd).format('YYYY年MM月') }}</div>
                <div class="info">
                    <div class="count">
                        总计({{ item.AllCount }})：<span>正常{{ item.NomalCount }}</span><span>异常{{ item.ErrCount
                            }}</span><span>未检{{ item.UnCheckCount }}</span>

                        <div class="fr" @click="changeOpen(i)">
                            {{ item.show ? '收起' : '展开' }}
                        </div>
                    </div>
                    <template v-if="item.show">
                        <div class="detail" v-if="item.Details.length">
                            <van-collapse v-model="activeName" accordion>
                                <van-collapse-item title="项目类型" :name="i">
                                    <div v-for="(child, ind) in item.Details" :key="ind">
                                        <div style="margin-bottom: 15px;">
                                            <div style="font-weight: bold;margin-bottom: 2px;"
                                                :style="{ color: child.ConfigTypeId == '' ? '#ff001d' : '' }">{{
                child.ConfigTypeName }}</div>
                                            <div
                                                style="display: grid;grid-template-columns: repeat(3, 1fr);text-align: left;font-size: 15px;color: #979797;;">
                                                <div>需检：{{ child.TypeCount }}</div>
                                                <div>已检：{{ child.CheckCount }}</div>
                                                <div>未检：<span style="color: #ff001d;">{{ child.UnCheckCount }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </van-collapse-item>
                            </van-collapse>
                            <van-collapse v-model="activeName2" accordion>
                                <van-collapse-item title="未检项目" :name="i">
                                    <div
                                        style="display: grid;grid-template-columns: repeat(3, 1fr);font-weight: bold;">
                                        <div>名称</div>
                                        <div>类型</div>
                                        <div>位置</div>
                                    </div>
                                    <div v-for="(child, ind2) in item.Configs" :key="ind2">
                                        <!-- <div style="display: grid;grid-template-columns: 1fr 10fr">
                                            <b style="padding: 5px;">{{ ind2 + 1 }}.</b>
                                            <div style="border-bottom: 1px solid #eee;padding: 5px;">
                                                <div>名称：{{ child.Name }}</div>
                                                <div>类型：{{ child.Type }}</div>
                                                <div>位置：{{ child.Loca }}</div>
                                            </div>
                                        </div> -->
                                        <div
                                            style="display: grid;grid-template-columns: repeat(3, 1fr);padding-bottom: 5px;">
                                            <div>{{ child.Name }}</div>
                                            <div>{{ child.Type||'-' }}</div>
                                            <div>{{ child.Loca||'-' }}</div>
                                        </div>
                                    </div>
                                </van-collapse-item>
                            </van-collapse>
                        </div>
                        <div class="detail" v-else>暂无数据</div>
                    </template>
                </div>
            </div>
        </div>
        <van-popup v-model="time.show" position="bottom" closeable @close="close">
            <div class="dateTit">选择时间</div>
            <!-- <div class="startEnd">
                <div class="start" :class="{ 'active': frame.DTS }">{{ frame.DTS }}</div>
                <div>至</div>
                <div class="end" :class="{ 'active': frame.DTE }">{{ frame.DTE }}</div>
            </div> -->
            <van-datetime-picker v-if="date == '日' || date == '周'" :type="time.dateType" @cancel="timeCancel"
                @confirm="timeConfirm" />
            <van-picker v-else show-toolbar :columns="columns" @cancel="timeCancel" @confirm="timeConfirm" />
            <!-- <div class="month">
                <div class="" v-for="(mm, min) in columns" :key="min">
                    {{ mm }}
                </div>
            </div> -->
        </van-popup>
        <van-popup v-model="scr.show" position="bottom" closeable @close="close">
            <van-picker show-toolbar :columns="screen" @cancel="screenCancel" @confirm="screenConfirm" />
        </van-popup>
    </div>
</template>

<script>
import moment from "moment";
// import { Toast } from 'vant';
export default {
    data() {
        return {
            data: {},
            list: [],

            frame: {
                DTS: '',
                DTE: '',
            },

            time: { dateType: '', show: false },
            columns: [],
            show: {
                now: false,
                ago: false
            },
            scr: { show: false },
            screen: ['全部', '正常', '异常'],
            activeName: 0,
            activeName2: 0
        }
    },
    props: {
        name: String,
        id: String,
        date: String,
        schId: String
    },
    created() {
        console.log(this.name, this.id)
        this.data.Safe_Class_Name = this.name
        this.data.Safe_Class_Id = Number(this.id)

        if (this.date == '日' || this.date == '周') {
            this.data.DTS = moment().startOf('month').format('YYYY-MM-DD')
            this.data.DTE = moment().endOf('month').format("YYYY-MM-DD")
        } else {
            this.data.DTS = moment().startOf('year').format('YYYY-MM-DD')
            this.data.DTE = moment().endOf('year').format("YYYY-MM-DD")
        }
        this.filter()
    },
    methods: {
        filter() {
            if (this.schId) {
                this.data.SCH_ID = this.schId
                this.getList1()
            } else {
                this.getList()
            }
        },
        changeScreen() {
            this.scr.show = true
        },
        screenCancel() {
            this.scr.show = false
        },

        screenConfirm(e) {
            console.log(e)
            if (e == '全部') {
                this.data.RESULT = ''
            } else if (e == '正常') {
                this.data.RESULT = '9'
            } else {
                this.data.RESULT = '1'
            }
            this.scr.show = false
            this.filter()
        },

        changeDate() {
            if (this.date == '日' || this.date == '周') {
                this.time.dateType = 'year-month'
                // this.columns = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
            } else {
                if (this.columns.length == 0) {
                    let yyyy = moment().format('YYYY')
                    let ts = this
                    for (var i = 0; i < 10; i++) {
                        console.log(i)
                        ts.columns.push(yyyy - i)
                    }
                    console.log(this.columns)
                }
            }
            this.time.show = true
            this.frame.DTS = ''
            this.frame.DTE = ''
        },
        timeConfirm(e) {
            if (this.date == '日' || this.date == '周') {
                this.data.DTS = moment(e).startOf('month').format('YYYY-MM-DD')
                this.data.DTE = moment(e).endOf('month').format("YYYY-MM-DD")
                console.log(this.data)
                // if (this.frame.DTS == '') {
                //     let year = moment().format("YYYY")
                //     let mm = ''
                //     if (e.split('月')[0] < 10) {
                //         mm = '0' + e.split('月')[0]
                //     } else {
                //         mm = e.split('月')[0]
                //     }
                //     console.log(mm)
                //     let sDay = year + '-' + mm + '-' + '01'
                //     console.log(sDay)
                //     this.data.DTS = sDay
                //     // this.data.DTS = moment(e).format('YYYY-MM-DD')
                //     this.frame.DTS = e
                // } else {
                //     let year = moment().format("YYYY")
                //     let mm = ''
                //     if (e.split('月')[0] < 10) {
                //         mm = '0' + e.split('月')[0]
                //     } else {
                //         mm = e.split('月')[0]
                //     }
                //     this.data.DTE = moment(year + mm).endOf('month').format("YYYY-MM-DD")
                //     // this.data.DTE = moment(e).endOf('month').format("YYYY-MM-DD")
                //     console.log(this.data.DTE)
                //     this.frame.DTE = e
                // }
            } else {
                // if (this.frame.DTS == '') {
                //     this.data.DTS = e + '-' + moment().startOf('year').format('MM-DD')
                //     // this.data.DTS = moment(e).format('YYYY-MM-DD')
                //     this.frame.DTS = e
                // } else {
                //     if (this.frame.DTS > e) {
                //         Toast('结束年份不能小于开始年份！');
                //         return
                //     } else if (this.frame.DTS <= e) {
                //         this.data.DTE = e + '-' + moment().endOf('year').format('MM-DD')
                //         this.frame.DTE = e
                //         console.log(this.data.DTE)
                //     }
                //      else {
                //         // this.data.DTE = moment(e).endOf('month').format("YYYY-MM-DD")
                //         this.data.DTE = e - 1 + '-' + moment().endOf('year').format('MM-DD')
                //         console.log(this.data.DTE)
                //         this.frame.DTE = e
                //     }
                // }
                this.data.DTS = e + '-' + moment().startOf('year').format('MM-DD')
                this.data.DTE = e + '-' + moment().endOf('year').format('MM-DD')
                console.log(this.data)
            }
            // if (this.frame.DTS != '' && this.frame.DTE != '') {
            this.time.show = false
            this.filter()
            // }
        },
        timeCancel() {
            // this.data.DTS = ''
            this.frame.DTS = ''
            // this.data.DTE = ''
            this.frame.DTE = ''
        },
        close() {
            this.time.show = false
        },
        getList() {
            let self = this
            this.whale.remote.getResult({
                url: '/api/Mobile/SAFE/RecApi/GetAllDataV2',
                data: self.data,
                completed(m) {
                    m.DATA.forEach(i => {
                        if (self.date == '日' || self.date == '周') {
                            if (i.TitleStart == moment().format('YYYY-MM-DD' || i.TitleStart == moment(new Date()).isoWeekday(1).format('YYYY-MM-DD'))) {
                                i.show = true
                            } else {
                                i.show = false
                            }
                        } else {
                            if (i.TitleStart == moment().startOf('month').format('YYYY-MM-DD')) {
                                i.show = true
                            } else {
                                i.show = false
                            }
                        }

                    })
                    self.list = m.DATA
                }
            })
        },
        getList1() {
            let self = this
            this.whale.remote.getResult({
                url: '/api/Mobile/SAFE/RecApi/GetAllDataOutsiders',
                data: self.data,
                completed(m) {
                    m.DATA.forEach(i => {
                        if (i.TitleStart == moment().startOf('month').format('YYYY-MM-DD')) {
                            i.show = true
                        } else {
                            i.show = false
                        }
                    })
                    self.list = m.DATA
                }
            })
        },
        changeOpen(i) {
            this.list.forEach((e, o) => {
                if (o == i) {
                    e.show = !e.show
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #f6f7f8;
    background: #fff;
    color: #222222;
    font-size: 14px;
    padding: 10px 10px;

    .tit {}

    .screen {}

    .date {
        img {
            width: 12px;
            height: 12px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 4px;
        }
    }
}

.center {
    .item {
        margin: 10px 0;

        .frame {
            width: 90%;
            margin: 0 auto 10px;
            font-weight: bold;

            span {
                color: #444444;
                font-size: 14px;
            }
        }

        .info {
            width: 90%;
            margin: 0 auto;

            .count {
                background: #58aaf2;
                color: #fff;
                padding: 5px 10px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                font-size: 14px;

                span {
                    margin: 0 5px;
                }

                .fr {
                    float: right;
                    cursor: default;
                }
            }

            .detail {
                background: #fff;
                padding: 10px 10px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;

                .pro-tit {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    span {
                        display: inline-block;
                        width: 20%;
                        font-size: 14px;
                        text-align: center;
                        font-weight: bold;
                        color: #444444;
                    }
                }

                .pro {
                    margin: 10px 0;
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    justify-content: space-between;

                    span {
                        display: inline-block;
                        width: 20%;
                        font-size: 12px;
                        text-align: center;
                    }

                    .suc {
                        color: #4cb72f;
                    }

                    .err {
                        color: red;
                    }
                }
            }
        }
    }
}

.van-popup {
    padding-top: 26px;
}

.dateTit {
    font-weight: bold;
    font-size: 16px;
    padding: 10px;
    text-align: center;


}

.startEnd {
    margin-top: 10px;
    display: flex;
    align-items: bottom;
    justify-content: space-between;
    width: 100%;

    .start,
    .end {
        width: 45%;
        border-bottom: 1px solid #222;
        text-align: center;
        font-weight: bold;

        &.active {
            color: #1777ff;
            border-bottom: 1px solid #1777ff;
        }
    }
}

.month {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
</style>